import "./App.css";
import { Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ThreeCircles } from "react-loader-spinner";
import Dashboard from "./Pages/Dashboard";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Pages/auth/Login";
import UserList from "./Pages/User/UserList";
import Profile from "./Pages/auth/Profile";
import ChangePassword from "./Pages/auth/ChangePassword";
import AddBusiness from "./Pages/Business/AddBusiness";
import AddEmployee from "./Pages/Employees/AddEmployee";
import AddCompany from "./Pages/Company/AddCompany";
import EmployeeListing from "./Pages/Employees/EmployeeListing";
import AddLoan from "./Pages/Loan/AddLoan";
import LoanListing from "./Pages/Loan/LoanListing";
import AddRTO from "./Pages/RTO/AddRTO";
import RTOListing from "./Pages/RTO/RTOListing";
import CompanyListing from "./Pages/Company/CompanyListing";
import ViewLoanCompanyHistory from "./Pages/Loan/ViewLoanCompanyHistory";
import ViewSalary from "./Pages/Employees/ViewSalary";
import GenerateSalary from "./Pages/Employees/GenerateSalary";
import SaleEmployeeSalary from "./Pages/Employees/SaleEmployeeSalary";
import AddHead from "./Pages/Expences/Head/addHead";
import AddSubhead from "./Pages/Expences/Subhead/addSubhead";
import AddExpence from "./Pages/Expences/AddExpence";
import ExpenceListing from "./Pages/Expences/ExpencesListing";
import PendingLoanListing from "./Pages/Loan/PendingLoanListing";
import RejectLoanListing from "./Pages/Loan/RejectLoanListing";
import RunningLoanListing from "./Pages/Loan/RunningLoanListing";
import AgreeLoanListing from "./Pages/Loan/AgreeLoanListing";
import AddRTOAgent from "./Pages/RTO/RTO Agent/AddRTOAgent";
import RTOAgentList from "./Pages/RTO/RTO Agent/RTOAgentList";
import AddDocument from "./Pages/AddDocument/AddDocument";
import AddPayment from "./Pages/RTO/Payment/AddPayment";
import PaymentHistory from "./Pages/RTO/Payment/PaymentHistory";
import AddInsurance from "./Pages/Insurance/AddInsurance";
import InsuranceListing from "./Pages/Insurance/InsuranceListing";
import Reciept from "./Pages/RTO/Payment/Reciept";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
    },{
      path: "/login",
      element: <Login />,
    },
    {
      path: "/add-company",
      element: <AddCompany />,
    },{
      path: "/company-listing",
      element: <CompanyListing />,
    },
    {
      path: "/add-employee",
      element: <AddEmployee/>,
    }, {
      path: "/employee-list",
      element: <EmployeeListing/>,
    }, {
      path: "/generate-salary",
      element: <GenerateSalary/>,
    }, {
      path: "/view-salary",
      element: <ViewSalary/>,
    },, {
      path: "/view-Sale-salary",
      element: <SaleEmployeeSalary/>,
    }, {
      path: "/add-head",
      element: <AddHead/>,
    }, {
      path: "/add-subhead",
      element: <AddSubhead/>,
    }, {
      path: "/add-expence",
      element: <AddExpence/>,
    }, {
      path: "/expence-listing",
      element: <ExpenceListing/>,
    },
    
    {
      path: "/add-loan",
      element: <AddLoan/>,
      // errorElement: <ErrorPage />,
      children: [
        {
          path: "basic-detail",
          element: "",
        },
        {
          path: "vehicle-detail",
          element:"",
        },
        {
          path: "loan-request",
          element: "",
        },
        {
          path: "loan-approved",
          element: "",
        },
        {
          path: "payment-withdrawal",
          element: "",
        },
      ],
    },
    
    
    
    
    
    
    
    
    
    
    
    {
      path: "/add-loan",
      element: <AddLoan/>,
    },{
      path: "/loan-listing",
      element: <LoanListing/>,
    },{
      path: "/pending-loan-listing",
      element: <PendingLoanListing/>,
    },{
      path: "/reject-loan-listing",
      element: <RejectLoanListing/>,
    },{
      path: "/running-loan-listing",
      element: <RunningLoanListing/>,
    },{
      path: "/agree-loan-listing",
      element: <AgreeLoanListing/>,
    }, {
      path: "/add-rto",
      element: <AddRTO/>,
    },{
      path: "/rto-listing",
      element: <RTOListing/>,
    }
   ,{
      path: "/user-list",
      element: <UserList/>,
    },{
      path:"/profile",
      element:<Profile/>
    },{
      path:"/change-password",
      element:<ChangePassword/>
    },{
      path:"/add-business",
      element:<AddBusiness/>
    },{
      path:"/history",
      element:<ViewLoanCompanyHistory/>
    },{
      path:"/add-rto-agent",
      element:<AddRTOAgent/>
    },{
      path:"/rto-agent-list",
      element:<RTOAgentList/>
    },{
      path:"/add-doc",
      element:<AddDocument/>
    },{
      path:"/rto-agent-payment",
      element:<AddPayment/>
    },{
      path:"/rto-agent-payment-history",
      element:<PaymentHistory/>
    },{
      path:"/add-insurance",
      element:<AddInsurance/>
    },{
      path:"/insurance-listing",
      element:<InsuranceListing/>
    },{
      path:"/reciept",
      element:<Reciept/>
    }
  ]);
  return (
    <Suspense
      fallback={
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <ThreeCircles
            height="80"
            width="80"
            radius="9"
            color="black"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      }
    >
      <ToastContainer />
 
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
